@import url('https://fonts.googleapis.com/css2?family=Germania+One&family=Gotu&family=Karma&family=Saira+Condensed&display=swap');


.flexigrid {
    outline: 1px solid;
    width: fit-content;
}

.cf-grid {
    background-color: rgba(255, 255, 255, 0.75);
}



/* -------------------------------- BASIC GRID SETUP -----------------------------------*/

[id*='-grid-landscape'] {
    grid-template-rows: repeat(14, 25px) repeat(14, 26px);
}

#char0-grid-landscape {
    grid-template-columns: repeat(8, 36px);
}

/* ---------- DIV BACKGROUNDS -----------*/
#char0-div {
    background-color: rgba(255, 255, 255, .75);
}

.flexibox[id*='cf-box'] {
    z-index: 525;
}

#char0-grid-landscape-ArmTxt {
    background-color: rgba(255, 247, 180, 1);
    margin-top: 1px;
}

#char0-grid-landscape-WeaTxt,
#char0-grid-landscape-WthTxt,
#char0-grid-landscape-WdbTxt,
#char0-grid-landscape-WdgTxt
{
    background-color: #ffcc99;
    margin-top: 1px;
}

#char0-grid-landscape-PotTxt,
#char0-grid-landscape-MagTxt,
#char0-grid-landscape-OthTxt
{
    background-color:#a9c54b;
    margin-top: 1px;
}

.flexibox[id*='boxSpt'],
.flexibox[id*='boxSpq']
{
    background-color: #c5d3fd;
    margin-top: 1px;
}

#char0-grid-landscape-SaveTxt
{
    background-color:  rgba(255, 247, 180, 1);
    margin-top: 1px;
}

#char0-grid-landscape-FolTxt, 
#char0-grid-landscape-RacTxt,
#char0-grid-landscape-AbiTxt
{
    background-color: #ccaaff;
    margin-top: 1px;
}

/* ---------- INPUT CELL DEFINITIONS -----------*/
.cf-cell {
    border: 0px solid rgb(129, 78, 78);
    background-color: transparent; 
    z-index: 600;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.cf-in {
    font-size: 16px;
    width: 96%;
    background-color:transparent;
    color: royalblue;
    
}

.cf-in[id*='Txt'] {
    text-align: center;
    font-size: 16px;
    color: black;
    font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    pointer-events: none;
    z-index: 650; 
}

.cf-in[id*='name']  {
    font-size: 14px;
    text-align: center;
    font-family: Papyrus, Milonga, Cantora One, Alike Angular, fantasy; font-size: 30px; font-weight: bold; z-index: 999;
    color: black;
}

.cf-in[id*='myDeity'] { font-size: 14px; }

/* 
.cf-in[id*='-myClass']  { }
.cf-in[id*='-myLevel'], .cf-in[id*='-myMov'] { font-size: 13px; }
.cf-in[id*='-myRace'], .cf-in[id*='-myAlign'] { font-size: 12px; padding-left: 1px; }
.cf-in[id*='-myLevel'], .cf-in[id*='-myHei'], .cf-in[id*='-myWei'], .cf-in[id*='-myMov'], .cf-in[id*='-myNatt'] { 
    text-align: center; }
.cf-in[id*='myStr'], .cf-in[id*='myInt'] , .cf-in[id*='myWis'] , .cf-in[id*='myDex'] , .cf-in[id*='myCon'] , .cf-in[id*='myCha'] {
    text-align: center; }
*/

[id*='Bon'] {
    font-size: 11px;
    color:rgb(104, 0, 6);
}

.cf-in[id*='myMov'] { font-size: 20px; }

.cf-in[id*='myToHit'],
.cf-in[id*='myAC'] {
    font-size: 18px;
    color: royalblue;
}

.cf-in[id*='ClassTxt'],
.cf-in[id*='LvlTxt'],
.cf-in[id*='NXPTxt'],
.cf-in[id*='XPTxt'],
.cf-in[id*='RaceTxt'],
.cf-in[id*='AliTxt'],
.cf-in[id*='DeiTxt'] {
    text-align: left;
    padding-left: 6px;
}

.cf-in[id*='myAC'] { font-size: 28px; }

.cf-in[id*='myXPnext'] { font-size: 11px }  
.cf-in[id*='ACTxt' ] { font-size: 18px; }

.cf-in[id*='myHPbase'] { text-align: center; }
.cf-in[id*='myHPcurr'] { font-size: 18px; text-align: center; color:#ff00aa  }
.cf-in[id*='myHPnote'] { color:#5b9f0a; font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; }

.cf-in[id*='myWth'] { color: rgb(153, 62, 1); font-size: 18px; font-weight: bold; }
.cf-in[id*='myWdb'] { color: rgb(2, 97, 61); font-size: 18px; font-weight: bold; }

.cf-in[id*='myFol'] { font-size: 14px; font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; text-align: left }

.cf-in[id*='mySav'] { font-size: 15px; color: royalblue; text-align: center; }
.cf-in[id*='SavTxt'] {  font-size: 12px; margin-left: 1px; font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; text-align: left;}
.cf-in[id*='mySbon'] { font-size: 12px; }

.cf-charlong-textbox { z-index: 500; color: royalblue; font-size: 12px; margin:0; padding:0; border: none; width: 96%; height: 96%; line-height: 12px; text-align: left }

.editable-link[id*='myOth'] { font-size: 16px; font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; }
.editable-input[id*='myOth'] { font-size: 16px; font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; }

.editable-link[id*='mySp'] { font-size: 14px; text-align: left; }
.editable-input[id*='mySp'] { font-size: 14px; text-align: center; padding: 3px; }
.cf-in[id*='myTsp'] { font-size: 14px; text-align: center; }
.cf-in[id*='myNsp'] { font-size: 14px; color: #ff00aa }

.cf-spellbutton {
    border: none;
    font-family: 'Saira Condensed';
    color: black;
}

/* 

.cf-in[id*='mySbon6'] { font-size: 10px; font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; color: royalblue; } */
/* .cf-in[id*='myWeap'], .cf-in[id*='myWth'], .cf-in[id*='myWdb'], .cf-in[id*='myWdmg'] { text-align: center; font-size: 11px; font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; }
.cf-in[id*='myWeap'] { font-size: 11px; text-align: left; }

.cf-in[id*='myWdb'] { color: rgb(104, 0, 6); font-size: 13px; }
.cf-in[id*='myWdmg'] { font-size: 11px; }
.cf-in[id*='myWth'], .cf-in[id*='myWdb'], .cf-in[id*='myWdmg'] { font-weight: bold; }
.cf-in[id*='myAb'] { text-align: center; }
.cf-in[id*='SavTxt'] {  font-size: 12px; margin-left: 1px; font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; text-align: left;}
.cf-in[id*='myFol'] { font-size: 11px; font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; }

.cf-in[id*='myTsp'] { font-size: 9px; text-align: center; }

.cf-in[id*='myMag'], .cf-in[id*='myPot'] { font-size: 9px; }
.cf-in[id*='myQpo'] { font-size: 9px; text-align: center; }

.cf-charlong-textbox { z-index: 500; font-family: 'Arial'; font-size: 10px; margin:0; padding:0; border: none; width: 96%; height: 96%; line-height: 9px; text-align: left }

.cf-in[id*='myXPbonus'], .cf-in[id*='myXPnext'] { font-size: 9px }
*/

/* z-index changes to make boxes work */
#char0-grid-landscape-RacTxt { z-index: 650 }
#cf-boxArmTxt,
#cf-boxWthTxt, #cf-boxWdpTxt,#cf-boxWdgTxt,
#cf-boxPotTxt, #cf-boxMagTxt,
#cf-boxFolTxt, #cf-boxRacTxt, #cf-boxSavTxt,
#cf-boxSt1, #cf-boxSt2, #cf-boxSt2, #cf-boxSt3, #cf-boxSt4,
#cf-boxArmTxt, #cf-boxWeaTxt, #cf-boxMoreTxt, #cf-boxOthTxt { z-index: 599 } /* was 651 */

/* fixes for when Txt fields got turned into buttons */
#char0-grid-landscape-ArmTxt { border-right: 0px solid black; }
#char0-grid-landscape-WeaTxt, #char0-grid-landscape-WthTxt, #char0-grid-landscape-WdbTxt{ border-right: 1px solid black; }
#char0-grid-landscape-PotTxt { border-right: 1px solid black; }
#char0-grid-landscape-MagTxt { border-right: 1px solid black; }
#char0-grid-landscape-OthTxt { border-right: 0px solid black; }

/* the extra thick borders */
#cf-boxStats,
#cf-boxStats2,
#cf-boxStats3 
{ outline: black solid 2px; }

#charphoto0 {
    max-height: 50px;
    z-index: 101;
    position: absolute;

}
/* ----------  LOAD BUTTONS ETC  ------------*/
.cf-load-but { width: 14px; font-size: 9px; margin:0; padding: 1px; text-align:right; }

.cf-load-but { 
    width: 100px; 
    font-family: 'Acme', sans-serif;
    font-size: 16px;
    z-index: 1000;
    text-align: center;
    color: rgba(0, 0, 0, 0.895);
    border: none;
    display: inline-block;
    padding-left: auto;
    padding-right: auto;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-right: 4px;
    margin-left: 0px;
    border: 1px black solid;
    border-radius: 4px; 
    text-align: center;
    vertical-align: middle;
    /* font-family: 'Caveat Brush', cursive; */
    background-color: rgba(217,211,161,0.51);
    }

.character-dropdown-landscape {
    display: inline-block;
        position: absolute;
        top: 10px; left:616px;
        background-color: transparent;
        margin-right: 0px;
        margin-left: 0px;
        border: none;
        border-radius: 4px; 
        text-align: center;
        vertical-align: middle;
        z-index: 720;
    }

.characters-menu-landscape {
    position: absolute;
    top: 30px;
    left: -20px;
    
    list-style-type: none;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0px;
    
    border: 0px solid grey;
    width: 124px;
    z-index: 1100;
    }
    
.characters-menu-landscape > li {
margin: 0;
background-color: white;
}

.characters-menu-landscape > li:hover {
background-color: lightgray;
}

.characters-menu-landscape > li > button {
width: 100%;
height: 100%;
text-align: left;
background:rgb(255, 246, 222);
color: inherit;
border: none;
padding-top: 5px;
padding-left: 12px;
margin: 0;
font-family: 'Acme', sans-serif;
font-size: 13pt;
cursor: pointer;

z-index: 1001;
}

div[id*="char0-grid-landscape-mySp"] {
    position: relative;
}

div[id*="char0-grid-landscape-mySp"] button[id*="mySp"] {
    position: absolute;
    top: 0px;
    left: 0px;
}

div[id*="char0-grid-landscape-mySp"] input[id*="mySp"] {
    position: absolute;
    top: 0px;
    left: 0px;
}

input[id^="myWeap"], input[id^="myArm"], input[id^="myPot"],input[id^="myMag"]
{
    width: 216px;
}

input[id^="myOth"]
{
    width: 252px;
}

#char0-grid-landscape input[id="myClass"], #char0-grid-landscape input[id="myLevel"]
{
    text-align: left;
    margin-left: 24px;
    font-size: 20px;
}

/* need this to overwrite charsheet0.css */
#char0-grid-landscape #char0-grid-landscape-myXP #myXP,
#char0-grid-landscape #XPTxt
 {
    text-align: center;
    padding-left: 0pt;
}

#char0-grid-landscape input[id^="myXPbonus"]
{
    font-size: 10pt;
    margin-top: 4pt;
    margin-right: 1pt;
}

#char0-grid-landscape #myHPnote {
    text-align: right;
}

#char0-grid-landscape #NXPTxt {
    text-align: center;
    padding-left: 0pt;
    width: 40pt;
}

#char0-grid-landscape input[id="myRace"], #char0-grid-landscape input[id="myAlign"], #char0-grid-landscape input[id="myDeity"] {
text-align: left;
    margin-left: 24px;
}

/* DON'T NEED - boxNXP removed
#char0-grid-landscape #cf-boxRace {
    margin-right: 4px !important;
}

 #char0-grid-landscape #cf-boxNXP {
    margin-left: -3px !important;
}
*/

#char0-grid-landscape #myHei, #char0-grid-landscape #myWei, #char0-grid-landscape #WeiTxt, #char0-grid-landscape #HeiTxt {
    text-align: center;
}

#char0-grid-landscape #char0-grid-landscape-gTotImg-img {
    margin-top: 14px;
}

#char0-grid-landscape #WthTxt, #char0-grid-landscape #WdbTxt {
 margin-top: 10px;
}

#char0-grid-landscape #AliTxt, #char0-grid-landscape #DeiTxt {
    text-align: left;
    padding-left: 6px;
}

#char0-grid-landscape #myXPnext {
    font-size: 8.5pt;
}

#char0-grid-landscape #mySbon6 {
    font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

#char0-grid-landscape div[id^="cf-boxHP"],
#char0-grid-landscape div[id="cf-boxAC"],
#char0-grid-landscape div[id^="cf-boxArm"],
#char0-grid-landscape div[id^="cf-boxSav"],
#char0-grid-landscape div[id^="cf-boxOth"],
#char0-grid-landscape div[id^="cf-boxS5"],
#char0-grid-landscape div[id^="cf-boxSpq25"]  
{
    margin-right: 0pt !important;
}

#char0-grid-landscape #cf-boxWeapons {
    border: 1pt black solid;
    border-right: 1pt black solid;
    z-index: 1040;
}

#char0-grid-landscape-gTotImg {
    pointer-events: none;
}

#char0-grid-landscape-gTotImg-img {
  width: 60%;
}

#char0-grid-landscape .spell-check-group {
    text-align: left;
    width: 100px;
    height: 15px;
    position: absolute;
    left: 40px;
    align-items: end;
    justify-content: flex-end;
    display: flex;
    z-index: 1125;
  }

  div[id^="char0-grid-landscape-mySp"] button {
    margin-left: 1px;
}


